import { EMPTY, Observable } from "rxjs";
import {
  EnvironmentInjector,
  Injectable,
  inject,
  runInInjectionContext,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  Auth,
  User,
  signOut,
  signInWithEmailAndPassword,
} from "@angular/fire/auth";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = inject(Auth);
  private _snackBar = inject(MatSnackBar);
  private router = inject(Router);
  private injectionContext = inject(EnvironmentInjector);

  private readonly horizontalPosition: MatSnackBarHorizontalPosition = "right";
  private readonly verticalPosition: MatSnackBarVerticalPosition = "top";

  user: Observable<User | null> = EMPTY;
  uid: string | null = null;
  email: string | null = null;
  error: string | null = null;

  authState: User | null = null;

  data$: Observable<any>;

  restoUID: any;

  constructor() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.authState = user;
        this.uid = user.uid;
        this.email = user.email;
        localStorage.setItem("user", JSON.stringify(this.authState));
      } else {
        this.authState = null;
        this.uid = null;
        this.email = null;
        localStorage.removeItem("user");
      }
    });
  }

  showLoginOk() {
    const ref = this._snackBar.open("Connexion réussie", "", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
      panelClass: "successSnack",
    });
  }

  showError() {
    const ref = this._snackBar.open(
      "Identifiant ou mot de passe incorrect",
      "",
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
        panelClass: "errorSnack",
      }
    );
  }

  emailCurrentUser() {
    return runInInjectionContext(this.injectionContext, async () => {
      return this.authState ? this.authState.email : null;
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null ? true : false;
  }

  // Resto

  async loginResto(email: string, password: string) {
    try {
      return runInInjectionContext(this.injectionContext, async () => {
        const userCredential = await signInWithEmailAndPassword(
          this.auth,
          email,
          password
        );
        this.authState = userCredential.user;
        this.uid = userCredential.user.uid;
        this.email = userCredential.user.email;
        localStorage.setItem("user", JSON.stringify(this.authState));
        this.showLoginOk();
        this.router.navigate(["tableau-de-bord"]);
      });
    } catch (error) {
      console.log("erreur", error);
      this.showError();
    }
  }

  async logOutResto() {
    try {
      return runInInjectionContext(this.injectionContext, async () => {
        await signOut(this.auth);
        this.authState = null;
        this.uid = null;
        this.email = null;
        localStorage.removeItem("user");
        this._snackBar.open("Déconnexion réussie", "", {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 3000,
          panelClass: "successSnack",
        });
        this.router.navigate(["/login"]);
      });
    } catch (error) {
      this.error = error.message;
    }
  }
}
